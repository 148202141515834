@import '../../node_modules/rfs/scss';
/* RCI202-35: had to ensure /kapra/ was lowercase for case-sensitive environments */
@font-face {
  font-family: 'Kapra';
  font-weight: 400;
  font-style: normal;
  src: local('Kapra'), url('../assets/font-families/Kapra/kapra.eot'); /* IE9 Compat Modes */
  src: local('Kapra'),
    url('../assets/font-families/Kapra/kapra.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/font-families/Kapra/Kapra/font.woff2')
      format('woff2'),
    url('../assets/font-families/Kapra/Kapra/font.woff') format('woff');
}

@font-face {
  font-family: 'Kapra';
  font-weight: 700;
  font-style: normal;
  src: local('Kapra'), url('../assets/font-families/Kapra/kapra.eot'); /* IE9 Compat Modes */
  src: local('Kapra'),
    url('../assets/font-families/Kapra/kapra.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/font-families/Kapra/KapraBold/font.woff2')
      format('woff2'),
    url('../assets/font-families/Kapra/KapraBold/font.woff') format('woff');
}

@font-face {
  font-family: 'Kapra';
  font-weight: 800;
  font-style: normal;
  src: local('Kapra'), url('../assets/font-families/Kapra/kapra.eot'); /* IE9 Compat Modes */
  src: local('Kapra'),
    url('../assets/font-families/Kapra/kapra.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/font-families/Kapra/KapraExtraBold/font.woff2')
      format('woff2'),
    url('../assets/font-families/Kapra/KapraExtraBold/font.woff') format('woff');
}

$font-family-fallbacks: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;

$font-family-heading: Kapra, $font-family-fallbacks;

$font-family-body: proxima-nova, $font-family-fallbacks;

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
  sup {
    font-size: 50%;
  }
}

sup {
  font-size: 0.6em;
  line-height: 0;
}

h1 {
  @include font-size(60px);
  text-transform: uppercase;
  margin: 0;
  line-height: 48px;
  margin-bottom: 36px;
}

h2 {
  @include font-size(96px);
  text-transform: uppercase;
  margin: 30px 0;
}

h1 + h2 {
  margin-top: -20px;
}

h3 {
  @include font-size(2.5rem);
}

h4 {
  @include font-size(2rem);
}

h5 {
  @include font-size(1.5rem);
}

p {
  @include font-size(1.5rem);
  @include margin-top(1rem);
  @include margin-bottom(1rem);
}
