@import '../../styles/config';

@mixin landingButtonPrimary {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.35s ease-in-out;
  background-color: #000c23;
  border: 1px solid #444;
  font-style: normal;
  border-radius: 4px;
  cursor: pointer;
  padding: 14px 40px;
  font-size: 14px;
}

@mixin landingButton {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.35s ease-in-out;
  &:hover {
    color: #fdbb11;
  }
}

.splash-intro {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .splash-overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .splash-intro-content {
    color: #fff;
    text-align: center;
    z-index: 2;
    p,
    h1 {
      margin: 0;
      font-weight: bold;
      text-transform: uppercase;
      font-family: $font-family-heading;
    }
    h1 {
      font-size: 50px;
      margin: 16px 0;
    }

    .icon {
      margin-bottom: 32px;
    }

    .splash-ctas {
      display: flex;
      flex-direction: column;
      align-items: center;

      .splash-cta {
        padding: 16px 0;

        button {
          @include landingButton();

          &.accept {
            @include landingButtonPrimary();
          }
          &.deny {
            padding: 14px 40px;
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }
}

.icon-intro-overlay {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  .icon-intro-actions {
    padding-bottom: 2%;
    .icon-intro-cta {
      button {
        @include landingButtonPrimary();
      }
    }
    .icon-intro-media-control {
      display: flex;
      justify-content: center;
      padding: 1em 0;
      button {
        border: 0;
        margin: 0 1em;
      }
    }
  }
}
