@import '../../styles/config';

.rooms-block {
  h1,
  h3,
  p {
    @include margin-top(1rem);
    @include margin-bottom(1rem);
  }
  h2 {
    margin: 0;
  }
  p {
    @include font-size(1.25rem);
    @include rfs(2rem, 'line-height');
    @include margin-top(0.5rem);
    @include margin-bottom(1.5rem);
  }

  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: block;
  }

  @include MaxMQ(md) {
    .mobile-only {
      display: block;
    }

    .desktop-only {
      display: none;
    }
  }

  .overview {
    position: relative;
    color: white;
    overflow: hidden;
    height: 100%;
    width: 100%;

    .background {
      position: absolute;
      z-index: 1;
      top: 0;
      width: 100%;
      height: 100%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .content {
      position: absolute;
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: 2;
      left: 0;
      right: 0;
      padding: 0 1em;

      @include MQ(md) {
        padding: 0 6em;
        bottom: 10%;
      }

      h1 {
        @include font-size(3.75rem);
        @include rfs(4rem, 'line-height');
        text-transform: uppercase;
        text-align: center;
      }

      .room-links {
        @include margin-top(2rem);
        display: flex;
        justify-content: space-around;
        @include rfs(2rem, gap);

        color: white;
        > div {
          flex-basis: 242px;
          position: relative;
          > div {
            width: 100%;
            padding-top: 100%;
            position: relative;

            > button {
              position: absolute;
              @include blank-button;
              clip-path: circle(50%);
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                transition: 500ms ease;
              }

              &:hover {
                img {
                  transform: scale(1.1);
                }
              }
            }
          }
          h3 {
            position: relative;
            z-index: 2;
            @include font-size(2rem);
            @include rfs('2rem', line-height);
            @include margin-top('.75rem');
            margin-bottom: 0;
            text-transform: uppercase;
            text-align: center;
          }
        }
      }
    }
  }

  .room-wrapper {
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .room {
    pointer-events: none;
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;

    .content-wrapper {
      pointer-events: all;
      position: absolute;
      z-index: 3;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-end;

      .background {
        position: absolute;
        z-index: 1;
        pointer-events: none;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .background-video {
        position: absolute;
        z-index: 1;
        pointer-events: none;
        width: 100%;
        height: 100%;
        transition: 0.8s ease-in-out;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &.complete {
          opacity: 0;
        }
      }

      article {
        position: relative;
        z-index: 2;
        color: white;
        width: 100%;
        padding: 4em 1em;
        background: linear-gradient(
          360deg,
          rgba(0, 12, 35, 0.7) 0%,
          rgba(0, 12, 35, 0) 100%
        );
        display: flex;
        justify-content: flex-start;
        transform: translateY(150%);
        animation: 0.6s slidein ease-in-out 0.8s forwards;
        padding: 16px;

        @include MQ(md) {
          padding: 80px;
        }

        .content {
          max-width: 800px;
          width: 100%;
          text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.45);
        }

        h2 {
          text-transform: uppercase;
          @include font-size(4rem);
        }

        button {
          background: transparent;
          border: none;
          color: #fff;
          font-size: 24px;
          font-family: $font-family-heading;
          text-transform: uppercase;
          &:before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 16px;
            background: url('../../assets/icons/back-chev-orange.png');
            color: #ffa500;
            margin-right: 16px;
            background-size: cover;
          }
        }
      }

      .features {
        display: flex;
        @include margin-top(1.5rem);
        @include margin-bottom(1.5rem);
        flex-direction: column;

        @include MQ(md) {
          flex-direction: row;
        }
        > div {
          display: flex;
          align-items: center;
          margin: 0.5em 0;
          @include rfs('1rem', 'gap');

          &:first-of-type {
            margin-left: 0;
          }

          @include MQ(md) {
            margin: 0 2em;
          }

          img {
            @include rfs('2rem', 'height');
          }
          p {
            text-align: left;
            margin: 0;
            font-size: 15px;
            @include rfs(1.2rem, 'line-height');
          }
        }
      }
    }
  }

  @include MaxMQ(md) {
    .overview {
      .background {
        img {
          object-position: top left;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 40%
          );
        }
      }

      .content {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        height: 100%;
        .room-links {
          position: relative;
          bottom: 0;
          margin: 0;
          gap: 5px;
          flex-wrap: wrap;

          > div {
            flex-basis: auto;
            width: 150px;
            margin: 0.5em 0;
          }
        }
      }
    }
  }
}

@keyframes slidein {
  from {
    transform: translateY(150%);
  }

  to {
    transform: translateY(0%);
  }
}
