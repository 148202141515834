@import '../../styles/config';

.booking {
  background: linear-gradient(180deg, #000c23 0%, rgba(0, 12, 35, 0.6) 100%),
    url('../../assets/images/booking-bg.jpg') center center/cover no-repeat;
  display: flex;
  justify-content: center;

  padding: 16px;

  @include MQ(md) {
    padding: 32px;
  }

  @include MQ(lg) {
    padding: 130px 54px;
  }

  .booking-container {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
    font-family: $font-family-body;
    font-size: 21px;
    width: 100%;
    @include MQ(md) {
    }
    @include MQ(lg) {
    }

    .booking-sailing {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
      margin-top: 60px;

      .booking-sailing-title {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-end;
        font-family: $font-family-heading;

        h1 {
          margin: 1em 0;
        }
        @include MQ(xl) {
          margin: 0;
        }
        @media screen and (max-width: 1280px) {
          width: 110%;
          margin: 5px;
        }

        button {
          background-color: transparent;
          position: relative;
          color: white;
          border: none;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 14px;
          line-height: 16.8px;
          font-family: $font-family-body;
          margin-left: 30px;
        }
      }

      h1 {
        font-family: $font-family-heading;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 25px;
        line-height: 52px;
        flex: 1;

        @include MQ(md) {
          font-weight: 700;
          font-size: 42px;
          line-height: 52px;
        }
      }
    }

    .booking-agent {
      max-width: 900px;

      &.authenticated {
        max-width: 1080px;
      }

      img {
        width: 51px;
        margin-bottom: 32px;
      }

      .booking-title {
        font-family: $font-family-heading;
        font-style: normal;
        font-weight: 700;
        @include font-size(4.25rem);
        line-height: 1;
        text-align: center;
        color: #ffffff;
        letter-spacing: 0.03em;
      }

      .agent-instruction {
        margin-bottom: 32px;
        @include font-size(1.4rem);
      }
    }

    .grey-line {
      width: calc(100% - 30px);
      height: 1px;
      background-color: #777777;
      margin: 0 auto;
    }

    .booking-agent-details {
      text-align: start;
      width: 100%;
      padding: 46px 5px 10px;

      @include MQ(lg) {
        display: flex;
        flex-wrap: wrap;
      }

      .agent-details-item {
        display: flex;
        flex: 50%;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        font-size: 21px;
        margin: 10px 0;
        font-family: $font-family-body;

        @include MQ(lg) {
          flex-direction: row;

          //width: 50%;
          //flex: 1 0 50%;
        }

        .agent-details-label {
          text-align: left;
          text-transform: uppercase;
          font-style: normal;
          line-height: 32px;
          margin-right: 16px;
          min-width: 156px;
          font-size: 21px;
        }

        .agent-details-text {
          text-align: left;
          font-style: normal;
          line-height: 32px;
          margin-top: 0.5px;
          width: 100%;
          max-width: 300px;
          line-break: anywhere;
          flex: 1;
        }
        .agency-name {
          text-transform: lowercase;
        }
        .agency-name::first-letter {
          text-transform: uppercase;
        }
      }
      .agent-details-item:nth-child(2) p,
      .agent-details-item:nth-child(4) p {
        min-width: 156px;
        @include MQ(lg) {
          margin-left: 20px;
          min-width: 75px;
        }
      }
    }

    .custom {
      font-size: 21px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .custom-message {
      padding: 0 20px 10px 0px;
    }

    .button-yellow,
    .btn-with-radius {
    }
  }
}

.form-sections {
  padding: 20px 20px;

  .title-info {
    background-color: #f9f8f7;
    position: relative;
    .form-section-header {
      padding: 0 16px;

      .close-button {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
      }

      button {
        border: none;
        font-weight: 900;
        font-family: $font-family-body;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 16.8px;
        color: #061456;
      }

      h1 {
        font-family: $font-family-heading;
        font-weight: 700;
        font-size: 30px;
        text-align: center;
        white-space: nowrap;
        color: #061456;
        word-wrap: break-word;
        text-transform: uppercase;
        white-space: break-spaces;
        line-height: 40px;
        margin-top: 20px;
        @include MQ(sm) {
          font-size: 20px;
          padding: 0;
        }

        @include MQ(md) {
          font-size: 31px;
          padding: 0;
        }
      }
    }
    form {
      padding-left: 50px;
      padding-right: 50px;

      .required-sign {
        color: red;
      }

      .error-section {
        margin-top: 10px;
        color: red;
      }

      label,
      p {
        font-family: $font-family-body;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.2px;
        letter-spacing: 1px;
        color: #4f4f4f;
      }

      label {
        input,
        select,
        textarea {
          padding: 10px 0 10px 10px;
          border: 1px solid #e0e0e0;
          &:focus {
            outline: 1px solid $brand-royal-blue;
          }
        }

        input.error:not(:focus),
        select.error:not(:focus) {
          border: 2px solid red;
        }
        .error-message {
          color: red;
        }
      }

      .line-break {
        border: 1px solid #e0e0e0;
        margin: 30px auto 20px;
      }

      .name-section {
        display: flex;
        flex-direction: column;

        @include MQ(md) {
          flex-direction: row;
        }

        .input-group {
          label {
            input {
              width: 224px;
            }
            select {
              min-width: 177px;
            }
          }
        }
      }

      .name-section .input-group:nth-child(2) {
        margin-right: 10px;
      }

      .mail-section {
        .input-group {
          .group {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            @include MQ(md) {
              gap: 20px;
              flex-direction: row;
            }
          }
        }

        .error-message {
          text-align: left;
        }

        input:nth-child(2) {
          width: 100%;
          @include MQ(md) {
            width: 400px;
          }
        }
      }

      .option-section {
        .error-message {
          color: red;
          text-align: left;
        }
        .options {
          display: flex;
          flex-direction: column;

          @include MQ(md) {
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: 20em 10em;
            grid-row: 2em 2em;
          }

          .option {
            display: flex;
            flex-direction: row;
            gap: 15px;
          }
        }
      }

      .detail-section {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include MQ(md) {
          flex-direction: row;
        }

        label {
          color: red;
          select {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }

      .criteria-section {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        span {
          font-family: $font-family-body;
          font-weight: 900;
          font-size: 12px;
          line-height: 14.4px;
          letter-spacing: 1px;
          color: #061456;
          text-decoration: underline;
        }

        .criteria-options {
          display: flex;
          flex-direction: column;

          @include MQ(md) {
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: 15em 25em;
            grid-row: 2em 2em;
          }

          label {
            input[type='checkbox'] {
              -ms-transform: scale(1.5);
              -moz-transform: scale(1.5);
              -webkit-transform: scale(1.5);
              -o-transform: scale(1.5);
              transform: scale(1.5);
              margin-right: 10px;
            }
          }
        }
      }

      .id-number-section {
        .input-group {
          label {
            p {
              margin: 40px auto 10px auto;
            }

            input {
              width: 100%;
              @include MQ(md) {
                width: 60%;
              }
            }
          }
        }
      }

      .comment-section {
        .input-group {
          label {
            p {
              margin: 40px auto 10px auto;
            }

            textarea {
              width: 100%;
              height: 127px;
            }
          }
        }
      }

      .submit-button {
        display: flex;
        justify-content: center;

        button {
          font-weight: 700;
          font-family: $font-family-body;
          font-size: 16px;
          line-height: 19.2px;
          color: #000c23;
          text-transform: uppercase;
          padding: 17px 40px;
          background-color: #febc11;
          margin-bottom: 55px;
          margin-top: 20px;
          border: none;
          border-radius: 6px;
        }
        button:hover {
          color: white;
          background-color: #061456;
        }
      }
    }
    .error-message {
      text-align: center;
      p {
        color: red;
      }
    }
  }

  .success-message {
    text-align: center;
    padding: 0 32px 32px;
    min-width: 340px;

    p {
      font-family: $font-family-body;
      margin-bottom: 32px;
      font-size: 21px;
    }

    button {
      font-size: 16px;
    }
  }
  .why-we-ask-button {
    border: 0;
    color: #061456;
    text-decoration: underline;
    font-weight: bold;
  }
}

.booking-itinerary-modal {
  background: #000c23;
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
  }

  .close-button {
    border: 0;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 24px;
    font-weight: bold;
  }
}
