@import '../../styles/config';

.loading {
  display: flex;
  align-items: center;
  padding: 160px 32px 32px;
}

.app {
  max-width: 1920px;
  margin: 0 auto;
}

section.hero {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

html body div .content,
html body div .slide-content {
  .content-button {
    display: inline-block;
    padding: 16px 32px;
    border-radius: 2px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 18px;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    margin-top: 2em;
  }

  .blue-button {
    background: $blue;
    color: #ffffff;
    @extend .content-button;
  }

  .purple-button {
    background-color: $purple;
    color: white;
    @extend .content-button;
  }

  .purple-blue-button {
    background: $purple-blue;
    color: #ffffff;
    @extend .content-button;
  }

  .pink-button {
    background-color: $pink;
    color: white;
    @extend .content-button;
  }

  .hot-pink-button {
    background-color: $hot-pink;
    color: white;
    @extend .content-button;
  }

  .yellow-button {
    background-color: $yellow;
    color: $blue;
    @extend .content-button;
  }

  .white-button {
    background: #ffffff;
    color: $blue;
    @extend .content-button;
  }
}
