@import '../../styles/config';

.agent-view {
  .double-content-block {
    padding-top: (#{$header-height + $header-agent-info-height});
  }
}

.double-content-block {
  padding-top: $header-height;
}

.double-content-block {
  position: relative;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 100px;
  .header {
    flex: 1;
    padding-top: 60px;
    > :first-child {
      margin-top: 0;
    }
  }

  .footer {
  }

  .content {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 1em;
    p {
      @include font-size(1.125rem);
      line-height: 32px;
      letter-spacing: 0.2px;
      max-width: 1000px;
    }
  }

  h1 {
    sup {
      font-size: 14px;
      position: relative;
      top: -18px;
    }
  }
}
