@import '../../styles/config';

.social-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .share-text {
    font-size: 16px;
    line-height: 30px;
    height: 100%;
    color: #fff;
  }
  .social-link {
    padding: 24px 18px;
    margin-left: 10px;

    a,
    .react-share__ShareButton {
      color: #fff !important;
    }

    .react-share__ShareButton:hover {
      color: #febc11 !important;
    }
  }
  .social-link + .social-link {
    margin-left: 0;
  }
}
