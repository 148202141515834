@import '../../styles/config';

.background {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  @include MQ(md) {
    background-size: unset;
  }
  .border-top {
    height: 16px;
    position: relative;
  }

  .border-bottom {
    height: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .gradient-bg {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .children {
    position: relative;
  }

  video {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    max-height: 150vh;
    object-fit: cover;
    @include MQ(md) {
      max-height: 100%;
    }
  }

  .fade-in-image {
    position: absolute;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    animation: 0.8s fadein ease-in-out forwards;
  }

  .fade-in-video {
    position: absolute;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    animation: 0.8s fadein ease-in-out forwards;
  }
}

@keyframes fadein {
  from {
    opaicty: 0;
  }

  to {
    opacity: 1;
  }
}
