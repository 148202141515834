@import '../../styles/config';

.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 10;
  overflow: auto;
  max-height: calc(100vh - 16px);
}
