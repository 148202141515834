@import '../../styles/config';
.video-block {
  position: relative;
  height: 100%;
  .media-controls {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 3em 4.5em;
    button {
      border: 0;
      background: transparent;
      margin: 0 0.5em;
    }
  }
}
