@import '../../styles/config';

.panel-slider-block {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  @include MQ(md) {
    flex-direction: row;
  }
  .content-side {
    max-width: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 0 40px;
  }
  .content-container {
    position: relative;
    text-align: center;
    color: white;
    padding-top: 40px;
    z-index: 1;
    max-width: 500px;
    :first-child {
      margin-top: 0;
    }
    h2 {
      line-height: 84px;
    }
    .content {
      max-width: 1000px;
      padding: 0 1em;
      margin: 0 auto;
      p {
        @include font-size(1.125rem);
        line-height: 32px;
        letter-spacing: 0.2px;
        max-width: 1000px;
      }
    }
  }

  .slide-side {
    width: 100%;
    @include MQ(md) {
      width: 55%;
    }
    .slider .slick-slider > .slick-list > .slick-track > .slick-slide .slide {
      @media only screen and (min-height: 1024px) {
        height: 800px;
      }
    }
  }
}
