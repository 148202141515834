@import './_config.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: $font-family-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000c23;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.app {
  position: relative;
}

// SPACING
.remove-margin {
  margin: 0;
}
.remove-padding {
  padding: 0;
}

// BUTTONS
button {
  cursor: pointer;
  background-color: transparent;
}

.btn-yellow {
  border: none;
  border-radius: 0;
  cursor: pointer;
  padding: 5px;
  background-color: #febc11;
  color: #000c23;
  height: 53px;
  font-family: $font-family-body;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 0 20px;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  @media screen and (768px) {
    font-size: 15px;
  }

  &.btn-with-radius {
    border-radius: 6px;
  }
}

button.text-only {
  @include blank-button;
}

button.link {
  @include blank-button;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

// TEXT

.bold {
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.mobile-only {
  display: none !important;
}

.desktop-only {
  display: block !important;
}

@include MaxMQ(md) {
  .mobile-only {
    display: block !important;
  }

  .desktop-only {
    display: none !important;
  }
}

h1 {
  @include MQ(md) {
    line-height: 96px;
    margin: 0;
  }
}
