.why-we-ask {
  padding: 40px;

  .title {
    display: flex;
    align-items: center;
    h1 {
      flex: 1;
      font-size: 56px;
    }

    button {
      border: 0;
      font-size: 18px;
      font-weight: bold;
      color: #545454;
      &::after {
        content: 'X';
        margin-left: 12px;
        display: inline-block;
      }
    }
  }
  .content {
    padding: 0 30px;

    h4 {
      font-size: 1.5rem;
    }
    p {
      font-size: 14px;
    }
  }

  ul {
    list-style: none;
    padding-left: 20px;
  }
}
