@import '../../styles/config';

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #203559;

  .accolades {
    margin: 70px 0 50px;

    align-items: center;
    justify-content: center;

    @include MQ(md) {
      display: flex;
      flex-wrap: nowrap;
    }

    .accolade {
      padding: 8px;

      @include MQ(md) {
        padding: 0 12px;
      }

      img {
        width: 150px;

        @include MQ(md) {
          height: 45px;
          width: auto;
        }
      }
    }
  }

  .social-links {
    padding-bottom: 32px;
  }

  .logo {
    margin-bottom: 10px;
    width: 184px;
  }

  .copyright-and-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 0;
    margin-bottom: 50px;

    @include MQ(md) {
      gap: 40px;
    }

    .copyright {
      color: #fff;
      padding: 0 8px;
      p {
        @include font-size(1.3rem);
      }
    }

    .links {
      a {
        color: #fff;
        text-decoration: none;
        padding-right: 10px;
        line-height: 30px;
      }

      .privacy-link::after {
        content: '';
        padding-left: 10px;
        color: $med-grey;
        font-size: 30px;
        text-align: center;
        position: relative;
        top: 5px;
        left: 0;
      }
    }
  }
}
