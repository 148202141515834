//   ========================================================================
//   Mixins
//   ========================================================================

//Media Queries
@mixin MQ($canvas) {
  // breakpoints

  @if $canvas == xs {
    //xs
    @media only screen and ($bp-min-xs) {
      @content;
    }
  } @else if $canvas == sm {
    //sm
    @media only screen and ($bp-min-sm) {
      @content;
    }
  } @else if $canvas == md {
    //md
    @media only screen and ($bp-min-md) {
      @content;
    }
  } @else if $canvas == lg {
    //lg
    @media only screen and ($bp-min-lg) {
      @content;
    }
  } @else if $canvas == lgr {
    //lgr
    @media only screen and ($bp-min-lgr) {
      @content;
    }
  } @else if $canvas == xl {
    //xl
    @media only screen and ($bp-min-xl) {
      @content;
    }
  } @else if $canvas == xlr {
    //xlr
    @media only screen and ($bp-min-xlr) {
      @content;
    }
  } @else if $canvas == xxl {
    //xxl
    @media only screen and ($bp-min-xxl) {
      @content;
    }
  }
}

@mixin MaxMQ($canvas) {
  // breakpoints

  @if $canvas == xs {
    //xs
    @media only screen and ($bp-max-xs) {
      @content;
    }
  } @else if $canvas == sm {
    //sm
    @media only screen and ($bp-max-sm) {
      @content;
    }
  } @else if $canvas == md {
    //md
    @media only screen and ($bp-max-md) {
      @content;
    }
  } @else if $canvas == lg {
    //lg
    @media only screen and ($bp-max-lg) {
      @content;
    }
  } @else if $canvas == lgr {
    //lgr
    @media only screen and ($bp-max-lgr) {
      @content;
    }
  } @else if $canvas == xl {
    //xl
    @media only screen and ($bp-max-xl) {
      @content;
    }
  } @else if $canvas == xlr {
    //xlr
    @media only screen and ($bp-max-xlr) {
      @content;
    }
  } @else if $canvas == xxl {
    //xxl
    @media only screen and ($bp-max-xxl) {
      @content;
    }
  }
}

@mixin blank-button {
  font-family: inherit;
  color: inherit;
  text-shadow: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: inherit;
  padding: 0;
  margin: 0;
}

@mixin full-height-slider($spacing) {
  .slick-slider {
    height: 100%;
    width: 100%;
    overflow: hidden;

    .slick-list {
      height: 100%;
      margin: 0 -#{$spacing};
      .slick-track {
        height: 100%;

        .slick-slide {
          height: 100%;
          > div {
            height: 100%;
            padding: 0 $spacing;
          }
        }
      }
    }

    .slick-arrow {
      @include blank-button;

      background-color: white;
      width: 112px;
      height: 112px;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.9;
      color: transparent;

      &:after {
        content: '';
        position: absolute;
        background-image: url(../assets/icons/right-arrow.png);
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

      position: absolute;
      z-index: 1;
    }

    .slick-prev {
      left: 25%;
      transform: rotate(180deg) translateY(50%);
      display: none !important;
    }

    .slick-next {
      right: 25%;
    }

    .slick-slide {
    }
  }
}
