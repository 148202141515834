@import '../../styles/config';

.agent-view {
  section {
    scroll-margin-top: 117px;
  }
}

section {
  scroll-margin-top: calc(117px - #{$header-agent-info-height});
}

.back-to-home {
  position: absolute;
  top: 120px;
  left: 0;
  z-index: 1;
  height: 32px;
  appearance: none;
  border: none;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 48px;
  gap: 9px;
  cursor: pointer;

  font-family: $font-family-body;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #061456;
  text-decoration: none;
}

.home-back-arrow {
  width: 8px;
  height: auto;
}
