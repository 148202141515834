@import '../../styles/config';

.agent-purl-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .agent-input-group {
    flex: 1 0 auto;
    width: 100%;
    box-sizing: border-box;
    @include MQ(lg) {
      &.left {
        width: 55%;
      }
      &.right {
        width: 45%;
      }
    }

    label {
      padding: 12px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      @include MQ(md) {
        flex-direction: row;
        align-items: center;
        padding: 0 12px;
      }

      p {
        white-space: nowrap;
        min-width: 156px;
        text-transform: uppercase;
      }

      input {
        @include MQ(md) {
          flex: 1 0 auto;
        }
      }

      p,
      input,
      textarea {
        font-size: 21px;
        font-weight: bold;
        box-sizing: border-box;
      }
      p {
        white-space: nowrap;
        margin: 0;
      }
      input,
      textarea {
        padding: 0 24px;
        min-height: 50px;
        font-family: $font-family-body;
        font-size: 21px;
        color: #ffffff;
        font-weight: normal;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #febc11;
      }

      textarea {
        resize: vertical;
        max-width: 100%;
        max-height: 600px;
      }
      &.error {
        input {
          border-color: red;
        }
      }
    }

    &.message-group {
      padding-top: 32px;
      label {
        flex-direction: column;
        align-items: flex-start;
        p {
          margin-bottom: 8px;
        }
        textarea {
          width: 100%;
          min-height: 200px;
          box-sizing: border-box;
          padding: 16px 24px;

          @include MQ(md) {
            min-height: 84px;
          }
        }

        textarea::placeholder {
          color: #ffffff;
        }
      }
    }

    .error-message {
      padding-left: 16px;
      margin: 0;
      font-size: 15px;
    }
  }
  .agent-submit-container {
    padding-top: 32px;
    text-align: center;
    width: 100%;
  }

  .agent-input-group:nth-child(2) p,
  .agent-input-group:nth-child(4) p {
    min-width: 156px;

    @include MQ(lg) {
      min-width: 85px;
    }
  }
}
