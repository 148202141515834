@import '../../styles/config';

.agent-view {
  .tabs-block {
    height: calc(100vh - #{$header-height} - #{$header-agent-info-height});
  }
}

.tabs-block {
  height: calc(100vh - #{$header-height});
}

.tabs-block {
  position: relative;
  color: white;
  overflow: hidden;

  h1,
  h2,
  h3 {
    position: relative;
    font-family: $font-family-heading;
    text-transform: uppercase;
    @include margin-top(1.5rem);
    @include margin-bottom(1.5rem);
    sup {
      font-size: 60%;
    }
  }

  p {
    @include font-size(1rem);
    @include margin-top(1.5rem);
    @include margin-bottom(1.5rem);
  }

  .slides-content {
    position: absolute;
    z-index: 3;
    text-align: center;
    width: 100%;
    h1 {
      @include font-size(5.25rem);
    }
  }

  .next-slide {
    @include blank-button;
    z-index: 3;
    position: absolute;
    background-color: white;
    background-image: url('../../assets/icons/next-chev-blue.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 72px;
    height: 72px;
    max-width: 6vw;
    max-height: 6vw;
    border-radius: 50%;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
  }

  .prev-slide {
    @include blank-button;
    z-index: 3;
    position: absolute;
    background-color: white;
    background-image: url('../../assets/icons/prev-chev-blue.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 75px;
    height: 75px;
    max-width: 6vw;
    max-height: 6vw;
    border-radius: 50%;
    top: 50%;
    left: 50px;
    transform: translateY(-50%);
  }

  .slides {
    position: relative;
    z-index: 2;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }

    .slide {
      position: relative;
      display: inline-block;
      width: 600px;
      height: 100%;
      white-space: normal;
      max-width: 30%;
      transition: 1s;
      cursor: pointer;

      &.selected {
        width: 95%;
        max-width: 95%;
        .placeholder-image {
          opacity: 0;
        }
      }

      .placeholder-image {
        opacity: 1;
        object-position: 50%;
        z-index: 2;
        position: absolute;
      }

      img,
      video {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: none;
        object-position: 66% center;
        transition: 0.3s;
        transition-timing-function: ease-in-out;
      }

      img {
        object-fit: cover;
      }

      &:hover:not(.selected) {
        img {
          scale: 1.1;
        }
      }

      &:before {
        content: '';
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 12, 35, 0.75) 13.94%,
          rgba(0, 12, 35, 0) 47.82%,
          rgba(0, 12, 35, 0) 72.08%,
          rgba(0, 12, 35, 0.5) 100%
        );
      }

      .slide-text {
        position: absolute;
        z-index: 3;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        button {
          @include blank-button;
          display: flex;
          text-align: left;
          height: calc(220px - 5vw);
          min-height: 160px;
          flex-direction: column;
          justify-content: flex-start;
          line-height: 44px;

          &:focus {
            outline: 1px dotted white;
          }

          h3 {
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.45);

            @include font-size(3rem);
            @include margin-left(1.25rem);
            @include margin-right(0.5rem);
            @include margin-top(0.5rem);
            @include margin-bottom(0.5rem);
          }
        }
        .slide-subheader {
          margin-left: 20px;
          margin-bottom: -12px;
        }
      }
    }
  }

  .slide-text-focused {
    position: absolute;
    z-index: 2;
    width: 100%;
    bottom: -100%;
    display: flex;
    align-items: flex-end;
    padding: 30px 40px 30px 140px;
    background: linear-gradient(
      360deg,
      rgba(0, 12, 35, 0.6) 0%,
      rgba(0, 12, 35, 0) 100%
    );

    &:before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: 0.3s ease-in-out;
      background: rgba(0, 12, 35, 0);
    }

    &.open {
      &:before {
        background: rgba(0, 12, 35, 0.7);
      }
    }

    .slide-title {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-bottom: 24px;
    }

    .slide-content-toggle {
      color: #fff;
      border: none;
      padding: 16px 32px 4px 8px;
      font-size: 18px;
      font-weight: bold;
      font-family: $font-family-body;
      position: relative;
      text-align: left;
      text-transform: uppercase;
      display: flex;

      &:after {
        content: '+';
        color: #ffa500;
        margin-left: 16px;
      }
      &.open {
        &:after {
          content: '-';
        }
      }
    }

    > article {
      flex-basis: calc(100% - 200px);
      h3 {
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.45);
        @include font-size(3.75rem);
        margin: 0 32px 0 0;
        line-height: 1em;
      }

      h2 {
        @include font-size(4.25rem);
      }

      .text-content {
        line-height: 2rem;
      }

      button {
        @include blank-button;
        @include font-size(2rem);
        font-family: $font-family-heading;
        text-transform: uppercase;
      }
    }

    > div {
      flex-basis: 200px;
      display: flex;
      justify-content: center;
      button {
        @include blank-button;
        @include font-size(1.5rem);
        font-family: $font-family-heading;
        text-transform: uppercase;

        display: flex;
        align-items: center;
        @include rfs(1rem, 'gap');

        &.audio-on {
        }

        &.audio-off {
        }

        img {
          @include rfs(2rem, 'height');
        }
      }
    }
  }

  @include MaxMQ(md) {
    h1,
    h3 {
      @include margin-top(0.75rem);
      @include margin-bottom(0.75rem);
    }

    p {
      @include font-size(1rem);
      @include margin-top(0.75rem);
      @include margin-bottom(0.75rem);
    }

    .slides-content {
      h1 {
        @include font-size(3.25rem);
      }
    }

    .next-slide {
      background-size: 40px;
      width: 40px;
      height: 40px;
      max-width: none;
      max-height: none;
      right: 10px;
    }

    .prev-slide {
      background-size: 40px;
      width: 40px;
      height: 40px;
      max-width: none;
      max-height: none;
      left: 10px;
    }

    .slides {
      .slide {
        width: 375px;
        &.selected {
          width: 90%;
          max-width: 90%;
        }
        .slide-text {
          h3 {
            @include font-size(1.5rem);
            @include margin-top(1.5rem);
            @include margin-bottom(1.5rem);
            @include margin-left(1.25rem);
          }
        }
      }
    }

    .slide-text-focused-mobile {
      position: absolute;
      z-index: 2;
      width: 90%;
      height: 100%;
      top: 0;
      background: rgba(0, 12, 35, 0);
      transition: 0.3s ease-in-out;

      &.open {
        background: rgba(0, 12, 35, 0.7);
      }

      .slide-content-toggle {
        color: #fff;
        border: none;
        padding: 16px 32px 4px 8px;
        font-size: 18px;
        font-weight: bold;
        font-family: $font-family-body;
        position: relative;
        text-align: left;
        text-transform: uppercase;
        display: flex;
        @include margin-left(2rem);
        margin-top: -24px;
        &:after {
          content: '+';
          color: #ffa500;
          margin-left: 16px;
        }
        &.open {
          &:after {
            content: '-';
          }
        }
      }

      .slides-content-mobile-heading {
        position: absolute;
        .slide-subheader {
          font-family: $font-family-body;
          font-size: 14px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: -2px;
          text-shadow: 0px 1px 1px rgba(0, 0, 0, 1);
          @include margin-left(3rem);
        }
        h3 {
          @include font-size(5.25rem);
          @include margin-left(3rem);
          margin-top: 0;
          text-shadow: 0px 1px 1px rgba(0, 0, 0, 1);
        }
      }
      .slides-content-mobile-copy {
        @include margin-left(3rem);
        @include margin-right(3rem);
        h2 {
          @include font-size(6rem);
          line-height: 1em;
        }
      }

      button.text-only {
        font-family: $font-family-heading;
        font-weight: bold;
        text-transform: uppercase;
        @include font-size(1.5rem);
      }
    }
  }
  .back-button {
    &:before {
      content: '';
      display: inline-block;
      width: 13px;
      height: 22px;
      background: url('../../assets/icons/back-chev-orange.png');
      color: #ffa500;
      margin-right: 16px;
    }
  }
  .slide-subheader {
    font-family: $font-family-body;
    font-size: 14px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 2px;
    margin-bottom: 2px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 1);
  }
}
