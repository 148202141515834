@import '../../styles/config';

.panel-block {
  position: relative;
  text-align: center;
  color: white;
  display: flex;
  height: 100%;

  &.panel-right {
    flex-direction: row-reverse;
  }
  .panel {
    height: 100%;
    flex: 1;
    max-width: 500px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include MQ(md) {
      padding: 0 80px;
    }
  }
  h1 {
    line-height: 62px;
  }
  .content {
    max-width: 1000px;
    margin: 0 auto;

    p {
      @include font-size(1.125rem);
      line-height: 32px;
      letter-spacing: 0.2px;
      max-width: 1000px;
    }
  }
}

.border-top + .panel-block {
  height: calc(100% - 16px);
}
