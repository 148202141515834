@import '../../styles/config';

.agent-view {
  .ship-sections {
    height: calc(100vh - #{$header-height} - #{$header-agent-info-height});
  }
}

.ship-sections {
  height: calc(100vh - #{$header-height});
  min-height: 700px;
}

.ship-sections {
  width: 100%;
  position: relative;
  .header {
    background-color: transparent;
    z-index: 2;
    position: absolute;
    top: 8vh;
    width: 100%;
    text-align: center;
    h1,
    p {
      margin: 0;
      color: #fff;
    }

    h1 {
      @include font-size(5.25rem);
      @include rfs(5.25rem, line-height);
    }

    @include MaxMQ(lg) {
      margin: 5%;
      top: 0;
      text-align: left;
      @include rfs(30rem, width);
      h3 {
        @include font-size(3rem);
        @include rfs(3rem, line-height);
      }
      p {
        @include font-size(1rem);
        @include rfs(1rem, line-height);
      }
    }
  }

  .header,
  .tiles {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .image-container {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    img {
      width: 100%;
      height: 100%;
      transition: all 1s ease;
      display: block;
      object-fit: cover;

      &.wide-bg {
        display: none;
      }

      &.tall-bg {
        display: block;
      }

      @include MQ(lg) {
        &.wide-bg {
          display: block;
        }

        &.tall-bg {
          display: none;
        }
      }
    }

    .overlay {
      @include MaxMQ(lg) {
        position: absolute;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          #000c23 7.64%,
          rgba(0, 12, 35, 0) 24.91%
        );
      }
    }

    .overlay {
      @include MaxMQ(lg) {
        position: absolute;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          #000c23 7.64%,
          rgba(0, 12, 35, 0) 24.91%
        );
      }
    }

    &.active {
      cursor: pointer;
    }
  }

  .tiles {
    position: absolute;
    bottom: 8%;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-around;
    padding: 0 5%;
    @include rfs(2rem, gap);
    color: white;

    > div {
      position: relative;
      flex-basis: 202px;
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;

      > div {
        position: relative;
        width: 100%;
        padding-top: 100%;

        > button {
          position: absolute;
          @include blank-button;
          clip-path: circle(50%);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            transition: 500ms ease;
            object-fit: cover;
          }

          &:hover {
            img {
              transform: scale(1.1);
            }
          }

          > .color-overlay {
            position: absolute;
            z-index: 2;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }

        > .tile-button-copy {
          pointer-events: none;
          position: absolute;
          z-index: 2;
          top: 0;
          left: -5%;
          width: 110%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          h3 {
            margin: 0;
            @include font-size(2rem);
            @include rfs('2rem', line-height);
            text-transform: uppercase;
            text-align: center;
            sup {
              font-size: 12px;
              position: relative;
              top: -1px;
              @include MQ(lg) {
                top: -7px;
              }
            }
          }
        }
      }
    }

    @include MaxMQ(lg) {
      flex-direction: column;
      flex-wrap: wrap;
      width: unset;
      bottom: 8%;
      height: 800px;
      gap: 0;

      > div {
        width: 80px;
        height: 80px;
        flex-basis: 80px;
        margin: 0px 0;
        > div > .tile-button-copy {
          width: 100%;
          left: 125%;
          justify-content: flex-start;
          h3 {
            text-align: left;
            font-size: 30px;
            line-height: 1em;
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.45);
          }
        }
      }
    }

    @include MaxMQ(md) {
      bottom: 11%;
      height: 500px;
      > div {
        width: 50px;
        height: 50px;
        flex-basis: 50px;
        > div > .tile-button-copy {
          width: 100%;
          left: 125%;
          justify-content: flex-start;
          h3 {
            text-align: left;
            font-size: 22px;
            line-height: 1em;
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.45);
          }
        }
      }
    }
  }

  .active-tile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s;
    transition-delay: 0.5s;

    &.active {
      opacity: 1;
      visibility: visible;
      z-index: 2;
    }

    .active-tile-title {
      display: flex;
      flex-direction: column;
      @include margin-bottom(1.5rem);

      h1 {
        line-height: 1em;
        margin-right: 32px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.45);
        margin-bottom: 0;
        sup {
          font-size: 14px;
          position: relative;
          top: -6px;
          @include MQ(lg) {
            top: -18px;
          }
        }
      }

      @include MQ(lg) {
        flex-direction: row;
        align-items: flex-end;
      }
    }
    .active-tile-image {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .active-tile-content-container {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: flex-end;

      .active-tile-content {
        position: relative;
        z-index: 2;
        width: 100%;
        color: #fff;
        background: linear-gradient(
          0deg,
          rgba(0, 12, 35, 0.7) 0%,
          rgba(0, 12, 35, 0) 100%
        );
        padding: 16px;

        &.open {
          &:before {
            background: rgba(0, 12, 35, 0.7);
          }
        }

        &:before {
          content: '';
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          transition: 0.3s ease-in-out;
          background: rgba(0, 12, 35, 0);
        }

        @include MQ(lg) {
          padding: 40px 80px 80px;
        }
        p {
          font-size: 16px;
          line-height: 1.4;
        }

        .tile-content {
          @include padding-bottom(1rem);
        }
        .back-button {
          background: transparent;
          border: none;
          color: #fff;
          font-size: 32px;
          font-family: $font-family-heading;
          text-transform: uppercase;
          &:before {
            content: '';
            display: inline-block;
            width: 13px;
            height: 22px;
            background: url('../../assets/icons/back-chev-orange.png');
            color: #ffa500;
            margin-right: 16px;
          }
        }
      }
    }

    .slide-content-toggle {
      color: #fff;
      border: none;
      padding: 16px 32px 4px 0px;
      font-size: 18px;
      font-weight: bold;
      font-family: $font-family-body;
      position: relative;
      text-align: left;
      text-transform: uppercase;
      display: flex;
      &:after {
        content: '+';
        color: #ffa500;
        margin-left: 16px;
      }
      &.open {
        &:after {
          content: '-';
        }
      }

      @include MQ(lg) {
        padding: 16px 32px 4px 8px;
      }
    }
  }
}
