@import '../../styles/config';

.hero {
  .background {
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .logo {
      text-align: center;
      margin-bottom: 2rem;
      padding: 0 1em;
      img {
        max-width: 100%;
      }
    }
    .copy {
      text-align: center;
      width: 100%;
      padding-bottom: 60px;
      .title {
        @include font-size(6.5rem);
        text-transform: uppercase;
        margin: 0;
        font-family: $font-family-heading;
      }

      .sub-title {
        font-family: $font-family-heading;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
        @include font-size(2rem);
      }

      .copy-content {
        max-width: 1000px;
        padding: 0 1em;
        margin: 0 auto;
        p {
          @include font-size(1.125rem);
          line-height: 32px;
          letter-spacing: 0.2px;
          max-width: 1000px;
        }
      }
    }
  }
}
