@import '../../styles/config';

.agent-view {
  .full-screen-section.account-for-header {
    height: calc(100vh - #{$header-height} - #{$header-agent-info-height});
  }
}

.full-screen-section {
  height: 100vh;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  object-fit: cover;

  &.account-for-header {
    height: calc(100vh - #{$header-height});
  }

  .gradient-bg {
    background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0) 40%,
      rgb(73 73 73) 100%
    );
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
  }
}
