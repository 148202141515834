@import '../../styles/config';
.content-block {
  position: relative;
  text-align: center;
  color: white;
  padding-top: 80px;
  :first-child {
    margin-top: 0;
  }
  .content {
    max-width: 1000px;
    padding: 0 1em;
    margin: 0 auto;
    p {
      @include font-size(1.125rem);
      line-height: 32px;
      letter-spacing: 0.2px;
      max-width: 1000px;
    }
  }
}

main {
  section:first-of-type {
    .content-block {
      padding-top: 180px;
    }
  }
}
