@import '../../styles/config';

.sailing-item-title-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 28px 20px;
  color: #ffffff;
  max-width: 100%;
  width: 500px;
  letter-spacing: 0.03em;
  border-bottom: 1px solid rgba(242, 242, 242, 0.4);

  .sailing-item-name {
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 0.03em;
    margin-bottom: 12px;
    color: #fbba12;
    border: 0;
    text-align: left;
    padding: 0;
  }
  .sailing-item-label {
    font-weight: 400;
    font-size: 14px;
  }
  .sailing-item-value {
    font-size: 16px;
  }

  p:not(.sailing-item-name) {
    line-height: 22px;
  }
}

.descriptions-font {
  font-size: 14px;
}
.descriptions-font-16 {
  font-size: 16px;
}
.descriptions-font-14 {
  font-size: 14px;
}
.descriptions-font-13 {
  font-size: 13px;
}

.sailings-same-destination {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  font-family: $font-family-body;
  margin: 0 10px;

  .sailing-item-title-box {
    border-bottom: 0;
  }

  @include MQ(xl) {
    flex-direction: row;
    margin: 0;
  }
  @media screen and (max-width: 1280px) {
    width: 110%;
    margin: 5px;
  }

  .sailing-image-box {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    flex-shrink: 0;
    width: 100%;
    height: 300px;
    border: 1px solid #828282;

    @include MQ(xl) {
      width: 300px;
      height: 100%;
    }
  }

  .sailing-items {
    background: rgba(0, 12, 35, 0.6);
    display: flex;
    text-align: center;
    border: 1px solid #828282;
    border-bottom: 0;
    font-size: 12px;
    flex-wrap: wrap;
    flex: 1;
    width: 100%;

    .sailing-item {
      display: block;
      text-align: start;
      width: 100%;
      border-bottom: 1px solid #828282;

      @include MQ(xl) {
        display: flex;
        text-align: start;
        border-bottom: 1px solid #828282;
        font-size: 12px;
        flex-wrap: wrap;
      }

      .sailing-item-title-box {
        border-right: 0;

        @include MQ(xl) {
          border-right: 1px solid #828282;
        }
      }

      .sailing-item-descriptions {
        border: 0;

        @include MQ(xl) {
          display: flex;
          flex-wrap: wrap;
          flex: 1;
        }

        .sailing-item-description-box {
          display: flex;
          flex-direction: column;
          font-weight: 400;
          letter-spacing: 0.03em;
          padding: 28px 20px;
          border-right: 1px solid #828282;
          min-width: 18%;
          flex: 1;
          flex-wrap: wrap;
          justify-content: center;

          .sailing-price {
            padding: 5px 0;
            font-size: 14px;
            line-height: 14px;

            @include MQ(md) {
              font-size: 16px;
            }
          }

          span {
            padding: 1px 0;
          }

          @media screen and (max-width: 1280px) {
            border-top: 1px solid grey;
          }
        }

        .sailing-item-description-box:last-child {
          border-bottom: 1px solid grey;
          @include MQ(xl) {
            border-bottom: 0;
          }
        }
      }

      .sailing-item-button-box {
        display: flex;
        align-items: center;
        padding: 15px;
        justify-content: center;
        width: 100%;

        @include MQ(xl) {
          width: 240px;
          border-top: 0;
        }

        button {
          color: #000c23;
          font-size: 12px;
        }
        button:hover {
          color: white;
          background-color: #061456;
        }
      }
    }
  }
}

.sailings-different-destinations {
  text-align: start;
  font-family: $font-family-body;
  width: 100%;

  .sailing-items {
    background: rgba(0, 12, 35, 0.6);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;

    @include MQ(md) {
      flex-direction: row;
    }

    .sailing-item {
      width: 100%;
      border: 1px solid rgba(242, 242, 242, 0.4);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 15px;

      @include MQ(md) {
        width: 49%;
      }
      @include MQ(xl) {
        width: 24%;
      }

      img {
        width: 100%;
      }

      button {
        color: #000c23;
        width: 100%;
      }
      button:hover {
        color: white;
        background-color: #061456;
      }

      .sailing-item-descriptions {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
      }

      .description {
        width: 100%;
        padding: 15px;

        @media screen and (min-width: 480px) {
          width: 40%;
          flex: 0 0 1;
        }

        @media screen and (min-width: 1180px) and (max-width: 1280px) {
          width: 39%;
          flex: 0 0 1;
        }

        span {
          padding: 1px 0;
        }

        .sailing-price {
          padding: 5px 0;
          font-size: 16px;
        }

        @include MQ(md) {
          border-left: 0;
          border-bottom: 0;
        }
      }
      .description:nth-child(1) {
        border-right: 0;
        @include MQ(xs) {
          border-right: 1px solid rgba(242, 242, 242, 0.4);
          border-bottom: 1px solid rgba(242, 242, 242, 0.4);
        }
        @media screen and (max-width: 481px) {
          border-right: 0;
        }
      }
      .description:nth-child(2) {
        border: 0;
        @include MQ(xs) {
          border-bottom: 1px solid rgba(242, 242, 242, 0.4);
          flex: 1;
        }
      }
      .description:nth-child(3) {
        border-right: 0;
        @include MQ(xs) {
          border-right: 1px solid rgba(242, 242, 242, 0.4);
        }
        @media screen and (max-width: 481px) {
          border-right: 0;
        }
      }
      .description:nth-child(4) {
        border-top: 0;
        @media screen and (max-width: 481px) {
          border-top: 1px solid rgba(242, 242, 242, 0.4);
        }
      }
    }
  }
}

.taxes-and-fees {
  margin-top: 2px;
  font-size: 13px;
}

.sailing-disclaimer {
  margin-top: 32px;

  p {
    text-align: left;
    font-size: 13px;
  }
}
