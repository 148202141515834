@import '../../styles/config';

.agent-view {
  .slider {
    height: calc(100vh - #{$header-height} - #{$header-agent-info-height});
  }
}
.slider {
  height: calc(100vh - #{$header-height});
}

.slider {
  .slick-slider,
  .slick-slider > .slick-list,
  .slick-slider > .slick-list > .slick-track,
  .slick-slider > .slick-list > .slick-track > .slick-slide,
  .slick-slider > .slick-list > .slick-track > .slick-slide > div,
  .slick-slider > .slick-list > .slick-track > .slick-slide > div > div,
  .slick-slider
    > .slick-list
    > .slick-track
    > .slick-slide
    > div
    > div
    > .slide {
    height: 100%;
  }

  .slick-arrow {
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease;

    img {
      width: 32px;

      @include MQ(lg) {
        width: 72px;
      }
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
    &.slick-prev {
      left: 4px;
      @include MQ(lg) {
        left: 16px;
      }
      &:before {
        content: '';
      }
    }
    &.slick-next {
      right: 24px;
      @include MQ(lg) {
        right: calc(16.666% + 24px);
      }
      &:before {
        content: '';
      }
    }
  }
  .slick-slider > .slick-list > .slick-track {
    margin: 0 -8px;
    > .slick-slide > div {
      padding: 0 8px;
    }
  }

  .slick-slider > .slick-list > .slick-track > .slick-slide {
    .slide {
      position: relative;
      height: 100%;
      img,
      video {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
        transition-timing-function: ease-in-out;
      }

      &.open {
        .placeholder-image {
          opacity: 0;
        }
      }

      .placeholder-image {
        opacity: 1;
        object-position: 50%;
        z-index: 2;
        position: absolute;
        transition: 300ms;
      }

      .slide-content-container {
        position: absolute;
        z-index: 2;
        min-height: 215px;
        bottom: 0;
        left: 0;
        width: 100%;

        height: auto;
        text-align: left;
        padding: 2.5% 4%;
        background: linear-gradient(
          0deg,
          rgba(0, 12, 35, 0.7) 0%,
          rgba(0, 12, 35, 0) 100%
        );
        color: #fff;
        display: flex;
        align-items: center;
        &.open {
          &:before {
            background: rgba(0, 12, 35, 0.7);
          }
        }
        &:before {
          content: '';
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          z-index: -1;
          transition: 0.3s ease-in-out;
          background: rgba(0, 12, 35, 0);
        }

        .slide-subheader {
          font-family: $font-family-body;
          font-size: 14px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          font-weight: bold;
          margin-left: 2px;
          margin-bottom: 2px;
          text-shadow: 0px 1px 1px rgba(0, 0, 0, 1);
        }

        .slide-header {
          .slide-title {
            margin: 0 16px 0 0;
            line-height: 1;
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.45);
            display: inline;
          }
          .slide-content-toggle {
            color: #fff;
            border: none;
            padding: 16px 32px 4px 8px;
            font-size: 18px;
            font-weight: bold;
            font-family: $font-family-body;
            position: relative;
            text-align: left;
            text-transform: uppercase;
            display: inline;
            white-space: nowrap;
            &:after {
              content: '+';
              color: #ffa500;
              margin-left: 16px;
            }
            &.open {
              &:after {
                content: '-';
              }
            }
          }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: $font-family-heading;
          font-style: normal;
          font-weight: 700;
          @include font-size(3.75rem);
          @include rfs(3.75rem, line-height);
          color: white;
          text-transform: uppercase;
        }
        p {
          font-family: $font-family-body;
          font-style: normal;
          font-weight: 500;
          @include font-size(1rem);
          @include rfs(2rem, line-height);
          letter-spacing: 0.02em;
        }
      }
    }
  }
}
