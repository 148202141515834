@import '../../styles/config';

header {
  background: transparent;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  box-sizing: border-box;
  width: 100vw;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.5s ease;

  .top-agent-info {
    background-color: #061456;
    display: flex;
    flex-direction: row;
    padding: 0 21px;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    height: $header-agent-info-height;
    position: relative;

    @include MQ(md) {
      justify-content: center;
      padding: 0;
    }
    .agent-contents-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px;
      max-width: 1000px;

      p {
        margin: 4px 0;
        color: #ffffff;
        font-family: $font-family-body;
        font-size: 10px;
        font-weight: 700;
        line-height: 32px;
        text-transform: uppercase;
        white-space: nowrap;

        .content,
        a {
          text-transform: none;
          text-decoration: none;
          color: #ffffff;
          font-size: 15px;
          font-weight: 500;
          vertical-align: bottom;

          @include MQ(md) {
            font-size: 14px;
          }
        }
      }
    }

    .return-button {
      position: absolute;
      left: 5em;
      display: flex;
      justify-content: center;
      a {
        color: white;
        font-size: 12px;
        font-weight: bold;
        text-decoration: none;
      }
      img {
        margin-right: 0.5em;
      }
    }

    .agent-contents-section p:nth-child(2),
    .agent-contents-section p:nth-child(3),
    .agent-contents-section p:nth-child(4) {
      display: none;

      @include MQ(md) {
        display: block;
      }
    }

    .icon-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;

      @include MQ(md) {
        display: none;
      }

      img {
        text-align: center;
        display: block;
      }
    }
  }

  .menu-items {
    height: $header-height-unscrolled;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: $deep;

    @include MQ(md) {
      flex-direction: row;
      padding: 0 40px;
    }

    @include MQ(xl) {
      padding: 0 80px;
    }

    .menu-left {
      display: flex;
      .hamburger-container {
        position: relative;
        width: 50px;
        height: 34px;
        padding: 0;
        background-color: transparent;
        border: none;
        appearance: none;

        div {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 4px;
          background: #fff;
          &:nth-child(2) {
            top: 14px;
          }
          &:last-child {
            top: 28px;
          }
        }
      }

      .social-links {
        display: none;
        @include MQ(lg) {
          display: flex;
        }
        padding-left: 32px;

        @include MQ(xl) {
          padding-left: 64px;
        }

        .social-link {
          svg {
            height: 20px;
          }
          padding: 0 12px;
        }
      }
    }

    .logo-container {
      flex: 1 0 auto;
      pointer-events: none;
      left: 0;
      align-items: center;

      img {
        width: 179px;
        height: auto;
      }
      @include MQ(md) {
        flex: 1 0 auto;
        justify-content: center;
        display: flex;
        position: absolute;
        width: calc(100% - 15px); /* account for scroll bar width*/

        img {
          width: 158px;
        }
      }
    }

    .cta-container {
      display: none;
      background-color: #febc11;
      border-radius: 6px;

      &:hover {
        background: #061456;
        a {
          color: #ffffff;
        }
      }

      a {
        font-family: $font-family-body;
        font-size: 13px;
        letter-spacing: 3px;
        font-weight: 700;
        text-decoration: none;
        padding: 11px 32px;
        display: inline-block;
        color: #061456;
        text-transform: uppercase;
      }

      @media screen and (min-width: 1023px) {
        display: block;
      }
    }
  }
}

.menu-drawer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  max-width: 375px;
  background-color: $deep;
  transform: translate(-100%, 0);
  transition: all 0.33s ease;
  display: flex;
  z-index: 4;
  .menu-drawer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    flex: 1 0 auto;
    position: relative;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }

  button:not(.react-share__ShareButton) {
    background-color: transparent;
    position: relative;
    top: 60px;
    left: 41px;
    color: white;
    border: none;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    line-height: 16.8px;
    font-family: $font-family-body;
  }

  &.active {
    transform: translate(0, 0);
  }

  .links {
    list-style: none;
    padding: 53px 8px 0 41px;
    margin-top: 40px;
    li {
      a {
        position: relative;
        transition: 0.1s ease-in-out;
        font-family: $font-family-heading;
        font-size: 36px;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 400;
        margin-bottom: 11px;
        display: inline-block;
        span.new {
          color: $yellow;
          font-size: 0.5em;
          position: absolute;
          top: 0;
          padding-left: 3px;
        }
        &:hover,
        &:focus {
          color: #febc11;
        }
      }
    }

    .last-link {
      transition: 0.1s ease-in-out;
      font-family: $font-family-heading;
      font-size: 36px;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 11px;
      display: inline-block;
      &:hover,
      &:focus {
        color: #febc11;
      }
      cursor: pointer;
    }
  }

  .drawer-footer {
    padding-bottom: 32px;

    .social-links {
      margin: 16px 0 0 41px;
      justify-content: flex-start;
      gap: 15px;

      .social-link {
        padding: 8px 8px 8px 0;
      }
    }

    .cta-container {
      background-color: #ffffff;
      margin: 0 41px;
      text-align: center;

      &:hover {
        background: #febc11;
        a {
          color: #061456;
        }
      }
      a {
        display: inline-block;
        font-family: $font-family-body;
        font-size: 16px;
        text-decoration: none;
        color: #000c23;
        font-weight: 900;
        line-height: 100%;
        letter-spacing: 2px;
        padding: 19px 10px;
      }
    }
  }
}
