// COLORS
$med-grey: #585858;
$pool-blue: #0bb5ff;
$adventures-green: #7fff00;
$feats-pink: #e61283;

$purple: #932fe5;
$purple-blue: #6f5af3;
$pink: #e018ba;
$hot-pink: #fe2c86;
$deep: #000c23;
$dark: #010d15;
$yellow: #febc11;

// BRANDS
// ROYAL
$brand-royal-blue: #2a467b;
$blue: #061456;

// FONTS
$default-font-color: $med-grey;

.white {
  color: #fff;
}
