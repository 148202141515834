@import '../../styles/config';

.itinerary-table {
  max-width: calc(100vw - 20px);
  width: 1024px;
  background: #203559;
  padding: 8px;
  color: #fff;

  @include MQ(md) {
    padding: 60px;
  }

  h3 {
    margin-top: 0px;
    text-align: center;
    text-transform: uppercase;
  }
  .image {
    display: flex;
    img {
      max-width: 100%;
    }
  }
  .header,
  .row {
    display: flex;

    p {
      margin: 0;
      &.name {
        flex: 1 0 auto;
        white-space: nowrap;
      }
    }
  }

  .header {
    margin-top: 8px;
    padding: 5px 18px;
    display: none;
    background: #000c23;

    @include MQ(md) {
      display: flex;
    }

    p {
      text-align: center;
      width: 120px;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .day {
      width: 200px;
    }
  }

  .row {
    padding: 12px 18px;
    background-color: #fff;
    color: #585858;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.05em;
    border-bottom: 1px solid #bdbdbd;

    flex-direction: column;

    &:last-child {
      border-bottom: 0;
    }

    @include MQ(md) {
      flex-direction: row;
    }

    .cell {
      display: flex;
      flex-direction: row;
      margin-bottom: 4px;
      width: 100%;

      @include MQ(md) {
        margin: 0;
        width: 120px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.day-cell {
        @include MQ(md) {
          width: 200px;
        }
      }

      &.name-cell {
        flex: 1 0 auto;
        white-space: nowrap;
        width: auto;
      }

      .label {
        margin-right: 10px;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        width: 90px;
        line-height: 23px;

        @include MQ(md) {
          display: none;
        }
      }
    }

    .day {
      text-align: left;
      width: 200px;
    }
    .arrival-time,
    .departure-time,
    .activity {
      text-align: left;
      @include MQ(md) {
        text-align: center;
      }
    }

    p {
      font-size: 16px;
      flex: 1;

      @include MQ(md) {
        font-size: 18px;
      }
    }
  }
}
